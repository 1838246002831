import Vue from "vue";
import Vuex from "vuex";
import VuexPersistence from "vuex-persist";
import axios from "axios";
import config from "./config.js";
import router from "./router.js";

Vue.use(Vuex);

const vuexLocal = new VuexPersistence({
  key: "bra-comm-admin-center",
  storage: window.localStorage,
});

const initialState = () => ({
  authToken: "",
  userName: "",
  userId: "",
  name: "",
  errorMessage: "",
  users: [],
  user_directory: [],
  user_directory_locations: [],
});

const store = new Vuex.Store({
  state: initialState,
  mutations: {
    setAuthToken(state, token) {
      state.authToken = token;
    },
    setUserName(state, name) {
      state.userName = name;
    },
    setName(state, name) {
      state.name = name;
    },
    setUserId(state, id) {
      state.userId = id;
    },
    setErrorMessage(state, message) {
      state.errorMessage = message;
    },
    setUsers(state, userlist) {
      state.users = userlist;
    },
    setUserDirectory(state, userlist) {
      state.user_directory = userlist;
    },
    setUserDirectoryLocations(state, location_list) {
      state.user_directory_locations = location_list;
    },
    resetState(state) {
      const initial = initialState();
      Object.keys(initial).forEach((key) => {
        state[key] = initial[key];
      });
    },
  },
  getters: {
    isAuthenticated: (state) => {
      return state.authToken !== "";
    },
  },
  actions: {
    login(state_obj, login_obj) {
      axios({
        method: "post",
        url: config.rc_api_url + "/api/v1/login",
        headers: {
          "Content-Type": "application/json",
        },
        data: {
          user: login_obj.email,
          password: login_obj.password,
        },
      })
        .then((response) => {
          if (response.data.status === "success") {
            if (response.data.data.me.roles.includes("admin")) {
              state_obj.commit("setAuthToken", response.data.data.authToken);
              state_obj.commit("setUserName", response.data.data.me.username);
              state_obj.commit("setName", response.data.data.me.name);
              state_obj.commit("setUserId", response.data.data.userId);
              router.push("/");
            }
          }
        })
        .catch(() => {
          state_obj.commit("setErrorMessage", "Email or Password Incorrect");
        });
    },
    logout(state_obj) {
      axios({
        method: "post",
        url: "https://chat.bra-communicator.com/api/v1/logout",
        headers: {
          "Content-Type": "application/json",
          "X-Auth-Token": state_obj.state.authToken,
          "X-User-Id": state_obj.state.userId,
        },
      });
      window.localStorage.removeItem("bra-comm-admin-center");
      state_obj.commit("resetState");
      router.push("/login");
    },
    fetchUsers(state) {
      axios({
        method: "post",
        url: config.bra_api_url + "/users-list",
        data: {
          userId: state.state.userId,
          authToken: state.state.authToken,
        },
      }).then((response) => {
        if (response.data.success) {
          response.data.users.forEach(function (user) {
            if (typeof user.emails !== "undefined") {
              if (typeof user.emails[0].address !== "undefined") {
                user.email = user.emails[0].address;
              } else {
                user.email = "";
              }
            }
          });
          state.commit("setUsers", response.data.users);
        }
      });
    },
    fetchUserDirectory(state) {
      axios({
        method: "post",
        url: config.bra_api_url + "/users/list",
        data: {
          userId: state.state.userId,
          authToken: state.state.authToken,
        },
      }).then((response) => {
        if (typeof response.data.result !== "undefined") {
          state.commit("setUserDirectory", response.data.result);
        }
      });
    },
    fetchUserDirectoryLocations(state) {
      axios({
        method: "post",
        url: config.bra_api_url + "/locations/list",
        data: {
          userId: state.state.userId,
          authToken: state.state.authToken,
        },
      }).then((response) => {
        if (typeof response.data.result !== "undefined") {
          state.commit("setUserDirectoryLocations", response.data.result);
        }
      });
    },
    setError(state_obj, message) {
      state_obj.commit("setErrorMessage", message);
    },
    clearError(state_obj) {
      if (state_obj.state.errorMessage !== "") {
        state_obj.commit("setErrorMessage", "");
      }
    },
  },
  modules: {},
  plugins: [vuexLocal.plugin],
});

export default store;
