<template>
  <div class="adduserform">
    <div class="add-user-message-container" v-if="addUserFormMessage !== ''">
      <div class="add-user-message" v-bind:class="{ error: addUserFormError }">
        {{ addUserFormMessage }}
      </div>
    </div>
    <form @submit.prevent="handleSubmit">
      <input
        class="name-input"
        type="text"
        v-model="input.name"
        placeholder="Full Name"
        required
        autocomplete="on"
      />
      <input
        class="email-input"
        type="email"
        v-model="input.email"
        placeholder="Email"
        required
        autocomplete="on"
      />
      <input
        class="password-input"
        type="password"
        v-model="input.password"
        placeholder="Password"
        required
      />
      <input
        class="password-input"
        type="password"
        v-model="input.password_confirm"
        placeholder="Confirm Password"
        required
      />
      <input
        class="phone-input"
        type="tel"
        v-model="input.phone"
        placeholder="Phone"
        pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}"
        required
        autocomplete="on"
      />
      <div class="phone-format">Format: 123-456-7890</div>
      <input
        class="phone-input"
        type="tel"
        v-model="input.altphone"
        placeholder="Alternate Phone (optional)"
        pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}"
        autocomplete="on"
      />
      <div class="phone-format">Format: 123-456-7890</div>
      <select class="select-css" v-model="input.location">
        <option :value="null" disabled>Location</option>
        <option v-for="(location, index) in locations" v-bind:key="index">{{ location }}</option>
      </select>
      <button class="add-user-button" type="submit">Add User</button>
    </form>
  </div>
</template>

<script>
import config from "@/config.js";
import axios from "axios";
export default {
  name: "AddUserForm",
  data() {
    return {
      input: {
        name: "",
        email: "",
        password: "",
        password_confirm: "",
        phone: "",
        location: null,
        altphone: "",
      },
      username: "",
      addUserFormMessage: "",
      addUserFormError: false,
    };
  },
  computed: {
    locations() {
      return config.location_list;
    },
  },
  methods: {
    setupUsername() {
      this.username = this.input.email.toLowerCase().replace(/@/g, "_at_");
    },
    handleSubmit() {
      this.addUserFormError = false;
      this.addUserFormMessage = "";
      if (this.input.password !== "" && this.input.password !== this.input.password_confirm) {
        this.addUserFormError = true;
        this.addUserFormMessage += "The passwords you entered do not match. ";
      }

      if (this.input.location == null) {
        this.addUserFormError = true;
        this.addUserFormMessage += "Please select a location. ";
      }

      if (
        this.input.name !== "" &&
        this.input.email !== "" &&
        this.input.password !== "" &&
        this.input.phone !== "" &&
        this.input.location !== null &&
        this.input.password === this.input.password_confirm
      ) {
        this.setupUsername();
        this.adduser();
      }
    },
    clearForm() {
      this.input = {
        name: "",
        email: "",
        password: "",
        password_confirm: "",
        location: null,
        phone: "",
        altphone: "",
      };
      this.username = "";
    },
    adduser() {
      axios({
        method: "post",
        url: config.rc_api_url + "/api/v1/users.create",
        headers: {
          "Content-Type": "application/json",
          "X-Auth-Token": this.$store.state.authToken,
          "X-User-Id": this.$store.state.userId,
        },
        data: {
          username: this.username,
          email: this.input.email,
          password: this.input.password,
          name: this.input.name,
          active: true,
          joinDefaultChannels: false,
          roles: ["user"],
          requirePasswordChange: false,
          sendWelcomeEmail: false,
          verified: true,
          customFields: {
            location: this.input.location,
            phone: this.input.phone,
            altphone: this.input.altphone,
          },
        },
      })
        .then((response) => {
          if (response.data.success) {
            this.addUserFormError = false;
            this.addUserFormMessage = "User has been added successfully.";
            this.$store.dispatch("fetchUsers");
            this.clearForm();
          } else {
            this.addUserFormError = true;
            this.addUserFormMessage =
              "There was an error adding the user. Check if the user's email already exists";
          }
        })
        .catch(() => {
          this.addUserFormError = true;
          this.addUserFormMessage =
            "There was an error adding the user. Check if the user's email already exists";
        });
    },
  },
};
</script>

<style scoped>
.adduserform {
  height: 800px;
  width: 320px;
  padding-left: 10px;
  padding-right: 10px;
  position: relative;
  background-color: #111111;
  text-align: center;
}
.adduserform form {
  max-width: 300px;
  padding: 10px;
  background-color: #1e2833;
  color: #fff;
  overflow: auto;
  border-radius: 4px;
}
.name-input,
.email-input,
.password-input,
.phone-input {
  background: none;
  padding: 5px;
  margin-top: 10px;
  margin-bottom: 5px;
  font-size: small;
  border: none;
  border-bottom: 1px solid #434a52;
  border-radius: 0;
  box-shadow: none;
  outline: none;
  color: inherit;
  display: inline-block;
  width: 90%;
}
.add-user-button {
  width: 50%;
  font-size: medium;
  cursor: pointer;
  background: #214a80;
  border: none;
  border-radius: 4px;
  padding: 11px;
  box-shadow: none;
  margin-top: 10px;
  text-shadow: none;
  outline: none;
  color: #fff;
}

.add-user-button:hover {
  opacity: 0.8;
}

.add-user-button:active {
  transform: translateY(1px);
}

.phone-format {
  font-size: x-small;
  color: #999;
}

.add-user-button:focus {
  outline: none;
}
.add-user-button::-moz-focus-inner {
  border: 0;
}
.add-user-message-container {
  padding-bottom: 20px;
}
.add-user-message {
  max-width: 320px;
  margin: auto;
  padding: 7px;
  border-radius: 4px;
  background: green;
}
.error {
  background-color: #530404;
}

.select-css {
  display: block;
  font-size: small;
  font-family: sans-serif;
  font-weight: 700;
  color: #ccc;
  line-height: 1.3;
  padding: 0.6em 1.4em 0.5em 0.8em;
  width: 100%;
  max-width: 100%;
  box-sizing: border-box;
  margin-left: 0px;
  margin-right: 0px;
  margin-top: 10px;
  margin-bottom: 10px;
  border: 1px solid #aaa;
  box-shadow: 0 1px 0 1px rgba(0, 0, 0, 0.04);
  border-radius: 0.5em;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  background-color: #000;
  background-image: url("data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22%23007CB2%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%3E"),
    linear-gradient(to bottom, #000000 0%, #222222 100%);
  background-repeat: no-repeat, repeat;
  background-position: right 0.7em top 50%, 0 0;
  background-size: 0.65em auto, 100%;
}
.select-css::-ms-expand {
  display: none;
}
.select-css:hover {
  border-color: #888;
}
.select-css:focus {
  border-color: #aaa;
  box-shadow: 0 0 1px 2px rgba(59, 153, 252, 0.7);
  color: #eee;
  outline: none;
}
.select-css option {
  font-weight: normal;
}
</style>
