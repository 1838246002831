<template>
  <div class="container">
    <div class="error-message" v-if="errorMessage">
      {{ errorMessage }}
    </div>
  </div>
</template>

<script>
export default {
  name: "ErrorMessage",
  computed: {
    errorMessage() {
      return this.$store.state.errorMessage;
    },
  },
};
</script>

<style scoped>
.container {
  width: 100%;
}

.error-message {
  background-color: #530404;
  max-width: 350px;
  width: 90%;
  margin: auto;
  padding: 7px;
  border-radius: 4px;
  text-align: center;
}
</style>
