<template>
  <div class="navbar">
    <div class="left-side">Admin Center</div>
    <div class="right-side">
      <nav class="nav-links">
        <router-link class="nav-link" to="/" v-if="isLoggedIn">Home</router-link>
        <router-link class="nav-link" to="/users" v-if="isLoggedIn"
          >RocketChat User Directory</router-link
        >
        <router-link class="nav-link" to="/user-directory" v-if="isLoggedIn"
          >BRA User Directory</router-link
        >
        <router-link class="nav-link" to="/support">Support</router-link>
        <router-link class="nav-link" to="/logout" v-if="isLoggedIn">Logout</router-link>
        <router-link class="nav-link" to="/login" v-if="!isLoggedIn">Login</router-link>
      </nav>
    </div>
  </div>
</template>

<script>
export default {
  name: "NavBar",
  components: {},
  computed: {
    isLoggedIn() {
      return this.$store.getters.isAuthenticated;
    },
  },
};
</script>

<style scoped>
.navbar {
  background: #333333;
  color: #dddddd;
  width: 100%;
  min-height: 50px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap-reverse;
  border-bottom: 1px solid #777;
  margin-bottom: 10px;
}
.nav-links {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  flex-wrap: wrap;
}
.nav-link {
  padding: 5px;
}
.left-side {
  height: 100%;
  font-size: 24px;
  min-height: 40px;
  margin-left: 40px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
}

.right-side {
  height: 100%;
  min-height: 40px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  padding-right: 10px;
}
nav a {
  color: #5af;
  font-size: medium;
}

nav a:hover {
  color: #5cf;
}

nav a.router-link-exact-active {
  font-weight: bold;
  color: #42b983;
}

nav a.router-link-exact-active:hover {
  color: #56f0aa;
}

@media all and (max-width: 500px) {
  nav a {
    font-size: small;
  }
}
</style>
