<template>
  <div v-if="can_view_info" class="user-view-container">
    <div class="section-header">User Info</div>
    <table>
      <tr>
        <td class="field-label">User ID:</td>
        <td class="field-value">{{ user.id }}</td>
      </tr>
      <tr>
        <td class="field-label">BRA Username:</td>
        <td class="field-value">{{ user.username }}</td>
      </tr>
      <tr>
        <td class="field-label">Email:</td>
        <td class="field-value">{{ user.email }}</td>
      </tr>
      <tr>
        <td class="field-label">Full Name:</td>
        <td class="field-value">{{ user.fullname }}</td>
      </tr>
      <tr>
        <td class="field-label">Location:</td>
        <td class="field-value">{{ location_name }}</td>
      </tr>
      <tr>
        <td class="field-label">Phone:</td>
        <td class="field-value">{{ user.phone }}</td>
      </tr>
      <tr>
        <td class="field-label">Date Created:</td>
        <td class="field-value">{{ date_string }}</td>
      </tr>
    </table>
    <div v-if="can_view_options">
      <div class="section-header">User Options</div>
      <div v-if="!has_options" class="no-options">No options set for user</div>
      <table v-if="has_options">
        <thead>
          <th>Name</th>
          <th>Value</th>
        </thead>
        <tr v-for="option in user_options" v-bind:key="option.id">
          <td>{{ option.option_name }}</td>
          <td>{{ option.option_value }}</td>
        </tr>
      </table>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import config from "@/config.js";
export default {
  name: "UserDirectoryUserView",
  props: {
    userid: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      user: false,
      user_options: false,
    };
  },
  computed: {
    can_view_info() {
      return this.user;
    },
    can_view_options() {
      return this.user_options;
    },
    has_options() {
      if (this.user_options) {
        return this.user_options.length !== 0;
      } else {
        return false;
      }
    },
    date_string() {
      if (typeof this.user.insert_date == "undefined") {
        return "";
      } else {
        var date = new Date(this.user.insert_date);
        return date.toLocaleTimeString() + " " + date.toLocaleDateString();
      }
    },
    location_name() {
      if (typeof this.user.location_id == "undefined") {
        return "";
      } else {
        let location = this.$store.state.user_directory_locations.find(
          (location) => location.id == this.user.location_id
        );
        return location.location_name;
      }
    },
  },
  created() {
    this.fetchUserInfo();
  },
  watch: {
    userid: function () {
      this.fetchUserInfo();
    },
  },
  methods: {
    fetchUserInfo() {
      axios({
        method: "post",
        url: config.bra_api_url + "/users/view",
        data: {
          userId: this.$store.state.userId,
          authToken: this.$store.state.authToken,
          user_id: this.userid,
        },
      }).then((response) => {
        if (typeof response.data.result !== "undefined") {
          this.user = response.data.result[0];
        }
      });
      axios({
        method: "post",
        url: config.bra_api_url + "/users/get-options",
        data: {
          userId: this.$store.state.userId,
          authToken: this.$store.state.authToken,
          user_id: this.userid,
        },
      }).then((response) => {
        if (typeof response.data.result !== "undefined") {
          this.user_options = response.data.result;
        }
      });
    },
  },
};
</script>

<style scoped>
.user-view-container {
  display: flex;
  flex-direction: column;
  align-content: center;
}
.section-header {
  border-bottom: 1px solid #aaa;
  font-size: 20px;
  text-align: center;
  padding-top: 10px;
}
.field-label {
  text-align: right;
  font-size: 16px;
  padding-right: 5px;
  color: #ccc;
  font-weight: bold;
}
.field-value {
  font-size: 16px;
  color: #fff;
}
.no-options {
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  padding: 10px;
}
table {
  border-spacing: 0;
  border-collapse: collapse;
  margin-left: auto;
  margin-right: auto;
  margin-top: 10px;
  margin-bottom: 10px;
}
table td {
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 10px;
  padding-right: 10px;
}
table tr:nth-child(odd):not(.noBackground) {
  background: rgba(0, 0, 0, 0.6);
}
table tr:nth-child(even):not(.noBackground) {
  background: rgba(0, 0, 0, 0.3);
}
</style>
