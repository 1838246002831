<template>
  <div class="footer-content">
    <div class="app-name">BRA Communicator</div>
    <div class="login-username" v-if="this.$store.getters.isAuthenticated">
      Logged in as: {{ currentUserName }}
    </div>
  </div>
</template>

<script>
export default {
  name: "Footer",
  computed: {
    currentUserName: function () {
      return this.$store.state.name;
    },
  },
};
</script>

<style scoped>
.footer-content {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  min-height: 30px;
  width: 100%;
  border-top: 1px solid #777;
}
.app-name {
  padding-left: 15px;
}
.login-username {
  color: #aaa;
  font-size: smaller;
  padding-right: 15px;
}
</style>
