<template>
  <div class="container">
    <div class="user-list">
      <table>
        <thead>
          <td>ID</td>
          <td>Username</td>
          <td>Email</td>
          <td>User Type</td>
          <td>Status</td>
          <td>Status Text</td>
          <td>Phone</td>
          <td>Location</td>
        </thead>
        <tr v-for="user in users" v-bind:key="user._id" v-bind:class="user.status">
          <td>{{ user._id }}</td>
          <td>{{ user.username }}</td>
          <td>{{ user.email }}</td>
          <td>{{ user.customFields.usertype }}</td>
          <td class="user-status">{{ user.status }}</td>
          <td v-bind:class="getFieldColor(user.statusText)">{{ user.statusText }}</td>
          <td class="user-phone">{{ user.customFields.phone }}</td>
          <td>{{ user.customFields.location }}</td>
        </tr>
      </table>
    </div>
  </div>
</template>

<script>
export default {
  name: "UserList",
  created() {
    this.$store.dispatch("fetchUsers");
  },
  computed: {
    users() {
      return this.$store.state.users;
    },
  },
  methods: {
    getFieldColor(message) {
      if (message == "At the Hospital - Reading All Studies") {
        return "green";
      } else if (message == "Not at the Hospital - Reading CT/MR/US/NM Only") {
        return "yellow";
      } else {
        return "red";
      }
    },
  },
};
</script>

<style scoped>
.user-list {
  text-align: center;
}
.user-list table {
  border-spacing: 0;
  border-collapse: collapse;
  width: 100%;
  font-size: 14px;
  text-align: left;
}
.user-list table thead {
  font-size: 14px;
  font-weight: bold;
  border-bottom: 1px solid white;
  text-align: center;
}
.user-list table td,
.user-list table thead td {
  padding-top: 4px;
  padding-bottom: 4px;
  padding-left: 8px;
  padding-right: 8px;
}
.user-phone {
  font-family: "Lucida Console", Monaco, monospace;
}
.user-list table tr:nth-child(odd):not(.noBackground) {
  background: #151515;
}
.user-list table tr:nth-child(even):not(.noBackground) {
  background: #202020;
}
td.user-status {
  text-align: center;
}
tr.online td.user-status,
.green {
  color: #00ff99;
}
.yellow {
  color: #f1f35f;
}
tr.offline td.user-status,
.red {
  color: #e04545;
}
</style>
