<template>
  <div class="supportform">
    <div class="centered" v-if="supportFormMessage !== ''">
      <div class="supportform-message" v-bind:class="{ error: supportFormError }">
        {{ supportFormMessage }}
      </div>
    </div>
    <div class="centered">
      <form @submit.prevent="handleSubmit">
        <h2>Contact Support</h2>
        <input
          class="text-input"
          type="text"
          v-model="input.name"
          placeholder="Full Name"
          required
          autocomplete="on"
        />
        <input
          class="text-input"
          type="email"
          v-model="input.email"
          placeholder="Email"
          required
          autocomplete="on"
        />
        <input
          class="text-input"
          type="tel"
          v-model="input.phone"
          placeholder="Phone (optional)"
          pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}"
          autocomplete="on"
        />
        <div class="phone-format">Format: 123-456-7890</div>
        <textarea
          class="message-input"
          rows="5"
          placeholder="Message"
          required
          v-model="input.message"
        />
        <Recaptcha></Recaptcha>
        <div class="centered">
          <button class="form-button" type="submit">Send</button>
        </div>
        <div class="centered">
          <div class="form-link" v-if="!this.$store.getters.isAuthenticated">
            <a href="/forgot-password">Forgot Password?</a>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import Recaptcha from "@/components/Recaptcha.vue";
import config from "@/config.js";
export default {
  name: "SupportContactForm",
  components: {
    Recaptcha
  },
  data() {
    return {
      input: {
        email: "",
        phone: "",
        name: "",
        message: ""
      },
      isError: false,
      supportFormMessage: "",
      supportFormError: false
    };
  },

  created: function() {
    this.supportFormMessage = "";
    this.supportFormError = false;
  },
  methods: {
    handleSubmit() {
      if (
        this.input.email !== "" &&
        this.input.message !== "" &&
        this.input.name !== "" &&
        window.grecaptcha.getResponse() !== ""
      ) {
        this.send_message();
      }
    },
    send_message() {
      let d = new Date();
      let message_body =
        "Email: " +
        this.input.email +
        "\n" +
        "Name: " +
        this.input.name +
        "\n" +
        "Phone: " +
        this.input.phone +
        "\n" +
        "Date: " +
        d +
        "\n" +
        "Message: " +
        "\n" +
        this.input.message;

      this.axios({
        method: "post",
        url: config.bra_api_url + "/support-email",
        headers: {
          "Content-Type": "application/json"
        },
        data: {
          message_subject: "[BRA SUPPORT] " + this.input.name,
          message_text: message_body
        }
      })
        .then(() => {
          this.supportFormMessage = "Support Message Sent Successfully";
        })
        .catch(() => {
          this.supportFormError = true;
          this.supportFormMessage = "There was an issue sending your support message.";
        });
    }
  }
};
</script>

<style scoped>
h2 {
  text-align: center;
}

textarea {
  width: 100%;
  background: #111;
  color: #ddd;
  margin-top: 20px;
  border-radius: 4px;
  padding: 7px;
}

textarea:focus {
  outline: none !important;
  border: 1px solid #5af;
}

.centered {
  display: flex;
  justify-content: center;
  align-items: center;
}
.supportform {
  height: 600px;
  position: relative;
  background-color: #111111;
}
.supportform form {
  max-width: 600px;
  background-color: #1e2833;
  padding: 40px;
  border-radius: 4px;
}
.text-input {
  background: none;
  padding: 10px 5px;
  margin: 8px 0;
  font-size: 16px;
  border: none;
  border-bottom: 1px solid #434a52;
  border-radius: 0;
  box-shadow: none;
  outline: none;
  color: inherit;
  display: inline-block;
  width: 100%;
}

.phone-format {
  font-size: 10px;
  color: #aaa;
  font-style: italic;
  text-align: center;
}
.form-button {
  width: 50%;
  font-size: large;
  cursor: pointer;
  background: #214a80;
  border: none;
  border-radius: 4px;
  padding: 11px;
  box-shadow: none;
  margin-top: 26px;
  margin-right: auto;
  margin-left: auto;
  text-shadow: none;
  outline: none;
  color: #fff;
}

.form-button:hover {
  opacity: 0.8;
}

.form-button:active {
  transform: translateY(1px);
}

.form-link {
  margin-top: 20px;
}

.form-link a {
  font-size: 14px;
  color: #5af;
}

.form-link a:hover {
  color: #5cf;
}

.form-button:focus,
.form-link a:focus {
  outline: none;
}
.form-button::-moz-focus-inner,
.form-link a::-moz-focus-inner {
  border: 0;
}
.supportform-message-container {
  padding-top: 10px;
  padding-bottom: 10px;
}
.supportform-message {
  max-width: 400px;
  text-align: center;
  margin: auto;
  padding: 7px;
  border-radius: 4px;
  background: green;
  margin-top: 5px;
  margin-bottom: 10px;
}
.error {
  background-color: #530404;
}
</style>
