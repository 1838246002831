<template>
  <div class="loginform">
    <form @submit.prevent="handleSubmit">
      <input
        class="email-input"
        type="email"
        v-model="input.email"
        placeholder="Email"
        required
        autocomplete="on"
      />
      <input
        class="password-input"
        type="password"
        v-model="input.password"
        placeholder="Password"
        required
      />
      <Recaptcha></Recaptcha>
      <button class="login-button" type="submit">Log In</button>
    </form>
  </div>
</template>

<script>
import Recaptcha from "@/components/Recaptcha.vue";
export default {
  name: "LoginForm",
  components: {
    Recaptcha
  },
  data() {
    return {
      input: {
        email: "",
        password: ""
      }
    };
  },
  methods: {
    handleSubmit() {
      if (
        this.input.email !== "" &&
        this.input.password !== "" &&
        window.grecaptcha.getResponse() !== ""
      ) {
        let login_obj = {
          email: this.input.email,
          password: this.input.password
        };
        this.$store.dispatch("login", login_obj);
      }
    }
  }
};
</script>

<style scoped>
.loginform {
  height: 600px;
  position: relative;
  background-color: #111111;
}
.loginform form {
  max-width: 320px;
  width: 90%;
  transform: translate(-50%, -50%);
  position: absolute;
  top: 50%;
  left: 50%;
  padding: 40px;
  background-color: #1e2833;
  color: #fff;
  overflow: auto;
  border-radius: 4px;
}
.email-input,
.password-input {
  background: none;
  padding: 10px 5px;
  margin: 8px 0;
  font-size: 16px;
  border: none;
  border-bottom: 1px solid #434a52;
  border-radius: 0;
  box-shadow: none;
  outline: none;
  color: inherit;
  display: inline-block;
  width: 90%;
}
.login-button {
  width: 50%;
  font-size: large;
  cursor: pointer;
  background: #214a80;
  border: none;
  border-radius: 4px;
  padding: 11px;
  box-shadow: none;
  margin-top: 26px;
  text-shadow: none;
  outline: none;
  color: #fff;
}
.login-button:hover {
  opacity: 0.8;
}
.login-button:active {
  transform: translateY(1px);
}
.login-button:focus {
  outline: none;
}
.login-button::-moz-focus-inner {
  border: 0;
}
</style>
