<template>
  <div id="app">
    <div id="container">
      <div class="header">
        <NavBar />
      </div>
      <div class="error">
        <ErrorMessage />
      </div>
      <div class="content">
        <transition name="fade" mode="out-in">
          <router-view />
        </transition>
      </div>
      <div class="footer">
        <Footer />
      </div>
    </div>
  </div>
</template>

<script>
import NavBar from "@/components/NavBar.vue";
import Footer from "@/components/Footer.vue";
import ErrorMessage from "@/components/ErrorMessage.vue";

export default {
  components: {
    NavBar,
    ErrorMessage,
    Footer,
  },
};
</script>

<style>
#app {
  font-family: "Avenir", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #cccccc;
  background: #111;
  height: 100%;
  width: 100%;
}
html,
body {
  margin: 0;
  padding: 0;
  background: #111;
  height: 100%;
}
.fade-enter-active,
.fade-leave-active {
  transition-duration: 0.2s;
  transition-property: opacity;
  transition-timing-function: ease;
}
.fade-enter,
.fade-leave-active {
  opacity: 0;
}
#container {
  height: 100%;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.header {
  min-height: 40px;
  flex-grow: 0;
  z-index: 9999;
}
.content {
  padding: 5px;
  flex-grow: 1;
  overflow: auto;
}
.footer {
  flex-grow: 0;
  min-height: 20px;
  background: #333;
}
</style>
