<template>
  <div class="container">
    <div class="add-user-form">
      <AddUserForm />
    </div>
    <div class="user-list">
      <UserList />
    </div>
  </div>
</template>

<script>
import UserList from "@/components/UserList.vue";
import AddUserForm from "@/components/AddUserForm.vue";

export default {
  name: "Users",
  components: {
    UserList,
    AddUserForm,
  },
};
</script>

<style scoped>
.container {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}
</style>
