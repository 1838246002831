let config;

config = {
  rc_api_url: "https://chat.bra-communicator.com",
  bra_api_url: "https://api.bra-communicator.com",
  radiologist_list: ["dougpeterson", "johnholmen", "lukeroller", "mikefortney", "mikeschirado"],
  location_list: [
    "Bowman",
    "Sakakawea/Hazen",
    "Elgin",
    "CHI Minot",
    "CHI Mandan",
    "CHI Garrison",
    "CHI Turtle Lake",
    "Harvey",
    "Linton",
    "Ashley",
    "Wishek",
    "Hettinger",
    "CHI St. Alexius Bismarck",
    "Other",
  ],
  rad_status_list: [
    "At the Hospital - Reading All Studies",
    "Not at the Hospital - Reading CT/MR/US/NM Only",
    "NOT CURRENTLY READING - PLEASE SEND TO VRAD",
    "Not on Call",
  ],
};

export default config;
