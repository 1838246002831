<template>
  <div class="user-directory-container">
    <div><UserDirectorySearch /></div>
  </div>
</template>

<script>
import UserDirectorySearch from "@/components/UserDirectorySearch.vue";

export default {
  name: "UserDirectory",
  components: {
    UserDirectorySearch,
  },
};
</script>

<style scoped>
.user-directory-container {
  height: 80vh;
}
</style>
