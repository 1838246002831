<template>
  <div class="home">
    <SetRadOnCall />
  </div>
</template>

<script>
import SetRadOnCall from "@/components/SetRadOnCall.vue";

export default {
  name: "Home",
  components: {
    SetRadOnCall,
  },
};
</script>
