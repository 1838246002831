import Vue from "vue";
import VueRouter from "vue-router";
import Home from "./views/Home.vue";
import Login from "./views/Login.vue";
import Support from "./views/Support.vue";
import Users from "./views/Users.vue";
import UserDirectory from "./views/UserDirectory.vue";
import store from "./store.js";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/users",
    name: "users",
    component: Users,
  },
  {
    path: "/user-directory",
    name: "user-directory",
    component: UserDirectory,
  },
  {
    path: "/support",
    name: "support",
    component: Support,
  },
  {
    path: "/login",
    name: "login",
    component: Login,
  },
  {
    path: "/logout",
    name: "logout",
    beforeEnter: () => {
      store.dispatch("logout");
    },
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  store.dispatch("clearError");
  if (!store.getters.isAuthenticated && to.path !== "/login" && to.path !== "/support") {
    next("/login");
  } else {
    next();
  }
});

export default router;
