<template>
  <div class="text-center">
    <div id="g-recaptcha" class="g-recaptcha" :data-sitekey="sitekey" data-theme="dark"></div>
  </div>
</template>

<script>
export default {
  name: "Recaptcha",
  data() {
    return {
      sitekey: "6LdaC-UUAAAAAAaiAMmfY11EM7zVb0-992lQKZse",
      widgetId: 0
    };
  },
  mounted() {
    let recaptchaScript = document.createElement("script");
    recaptchaScript.setAttribute("src", "https://www.google.com/recaptcha/api.js");
    recaptchaScript.async = true;
    recaptchaScript.defer = true;
    document.head.appendChild(recaptchaScript);
  }
};
</script>

<style scoped>
.text-center {
  text-align: center;
}
.g-recaptcha {
  margin-top: 30px;
  display: inline-block;
}
</style>
